/* Styles go here */
* {
  margin: 0;
  padding: 0;
  }

.App {
    background-color: #272640;
    color: lightgrey;
    height: 100%;
    font-size: 0.9rem;
}

body {
  background-color: #272640 !important;
  padding-top: 20px;
  padding-bottom: 100px;
}

.project-link{
  color: lightgrey !important;
}

.tenavbar {
  background-color: #272640;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: lightgrey;
}

.navbar-brand {
color: lightgrey !important;
}

.nav-link{
  color: lightgrey !important;
}

.navbar-toggler {
  color: lightgrey !important;
  border-color: lightgrey !important;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'><path stroke='rgba(211, 211, 211, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/></svg>") !important;
}

.navbar-toggler-icon {
  color: lightgrey !important;
}

/* .filter-bar {
  height: 100vh;
} */

/* .summary {
  font-size: 0.75rem; 
   box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; 
} */

.footer {
  position: fixed;
  background-color: #272640;
  color: lightgrey;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: lightgrey;
  /* clear:both; */
  padding-top:0px;
  /* box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; */
} 

.disclaimer {
  font-size: 0.75rem;
}

.button-te {
  font-weight: 500;
  background-color: white;
  border-radius: 10px;
  color: #272640;
}

.address-copy{
  font-weight: 500;
  background-color: white;
  border-radius: 10px;
  color: #272640;
  position: center;
  width: 35%;
  
}

.address-copy-span {
  display: inline-block;
  vertical-align: bottom;
  white-space: nowrap;
  width: calc(50% + 1.2em);
  /* max-width: 90px; */
  overflow: hidden;
  text-overflow: ellipsis;

}
/* .filter-bar-contents {
  position: absolute;
} */

.group-wrapper{
  /* margin-bottom: 25px; */
  border-radius: 6px;
  justify-content: center;
  /* border-color: white;
  border-style: solid 
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; */
}

.group-title {
  background-color: white;
  color: #312244;
  font-weight: bold;
  /* padding-left: 10%; */
  /* padding-right: 10%; */
  width: 100%;
  border-radius: 10px
}

.token-wrapper{
    margin-bottom: 25px;
    border-radius: 6px;
    justify-content: center;
}

.token {
    height: 150px;
    width: 150px;
    display: inline-block;
    background-color: #272640;
}

.bg-token {
    background-color: #312244;
    border-style: solid;
    border-radius: 20px;
    border-color: white;
    margin: .25%;
    color: white;
    padding: 0% !important;
    overflow: hidden;
}

.bg-token img {
    margin-top: 8px;
    margin-bottom: 8px;
    vertical-align: middle;
    width: 85%;
    border-radius: 20px;
  }

.suggestions {
  border-style: solid;
  border-color: white;
  border-radius: 20px;
  padding: 0% !important;
  overflow: hidden;
} 

.bg-token-sugg {
    /* background-color: #312244; */
    /* border-style: solid; */
    border-radius: 20px;
    border-color: white;
    margin: .25%;
    /* color: white; */
    padding: 5% !important;
    overflow: hidden;   
    position: relative; 
}

.bg-token-sugg img {
    margin-top: 8px;
    margin-bottom: 8px;
    vertical-align: middle;
    width: 70%; 
    border-radius: 20px; 
  }

.price {
  background-color: white;
  border-radius: 50px;
  color: #312244;
  font-weight: bold;
  width: 35%;
  height: 30px;
  padding: 0% !important;
  overflow: hidden; 
  position: absolute;
  z-index: 1;
  top: 2%;
  left: 2%
}

.rank {
  background-color: white;
  border-radius: 50px;
  color: #312244;
  /* font-weight: bold; */
  width: 40%;
  height: 30px;
  padding: 0% !important;
  overflow: hidden; 
  position: absolute;
  z-index: 1;
  bottom: 2%;
  right: 2%
}

.rt {
  width: 20px !important;
    vertical-align: center !important;
    margin-top: 5px !important;
    margin-left: 5px;
}

.eth {
  width: 15px !important;
    vertical-align: center !important;
    margin-top: 5px !important;
    /* margin-left: 50%; */
    
}

.token-name{
    font-weight: bold;
    background-color: white;
    color: #312244;
    width: 100%;
}

.token-image-bg{
  font-weight: bold;
  background-color: #312244;
  color: #312244;
  width: 100%;
  position: relative;
  z-index: 0;
 }

.token-image{
  position: absolute;
  z-index: 1;
}

.att-title {
    font-weight: bold;
    padding-right: 1%;
    /* text-align: right; */
}

.att-val {
    border-color: white;
    padding-left: 1%;
    text-align: left;
}

.att-title-sum {
  font-weight: bold;
  padding-right: 1%;
  min-width: 125px;
  /* text-align: right; */
}

.att-val-sum {
  border-color: white;
  padding-left: 1%;
  min-width: 60px;
  text-align: center;
}

.table-token {
    width: 100%;
    display: contents;
    overflow: auto;
    grid-template-columns:
    minmax(100px, 3fr)
    minmax(50px, 1fr);
  }

.table-group-summary {
    width: 100%;
    display: contents;
    overflow: auto;
    /* grid-template-columns:
    1fr
    1fr
    1fr */
  } 

.table-group-summary td {
  text-align: center;
  padding: 1px 1px; 
}  

.table-group-summary th {
  text-align: center;
  padding: 1px 1px;
}  

.table-token td {
    text-align: left;
    padding: 1px 20px;
     /* min-width: 100px;  */
    /* max-width: 50px; */
  }


.table-token td span {
  /* white-space: nowrap; */
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}

.address-input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  line-height: 15px;
  border: 1px solid #dfdfdf;
  border-radius: 3px;
  cursor: default;
  position: relative;
  background-color: #fff;
  position: relative;
    width: 100%;
    color: black;
    font-weight: 300;
    /* margin: 2px 10px; */
    margin-right: 15px;
}

/* DropDown Styles */
.dd-wrapper {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    position: relative;
    width: 100%;
    color: black;
  }
  .dd-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    line-height: 15px;
    border: 1px solid #dfdfdf;
    border-radius: 3px;
    cursor: default;
    position: relative;
    background-color: #fff;
  }
  .dd-header span {
    margin-right: 20px;
  }
  .dd-header-title {
    font-weight: 300;
    margin: 2px 10px;
    margin-right: 15px;
  }
  .angle-down {
    color: #000;
    margin-right: 10px;
  }
  .dd-list {
    z-index: 10;
    position: absolute;
    width: 100%;
    border: 1px solid #dfdfdf;
    border-top: none;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    background-color: #fff;
    -webkit-box-shadow: 0 2px 5px -1px #e8e8e8;
            box-shadow: 0 2px 5px -1px #e8e8e8;
    font-weight: 400;
    padding: 10px 0;
    max-height: 215px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
  .dd-list-item {
    width: 100%;
    font-size: 15px; 
    padding: 8px 10px;
    line-height: 15px;
    cursor: default;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .dd-list-item.selected {
    color: #fff;
    background-color: #312244;
  }
  .dd-list-item:hover {
    color: #fff;
    background-color: #312244;
  }
  .dd-wrapper-single {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    position: relative;
    width: 265px;
  }
  .dd-wrapper-single .dd-header {
    border: 1px solid #ccc;
  }
  .dd-wrapper-single .dd-header .dd-header-name {
    font-weight: 400;
  }
  .dd-wrapper-single .dd-list {
    border: 1px solid #ccc;
    border-top: none;
  }

  /**
 * ==============================================
 * Dot Falling
 * ==============================================
 */
.dot-falling {
  position: relative;
  display:inline-block;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: lightgrey;
  color: lightgrey;
  box-shadow: 9999px 0 0 0 lightgrey;
  animation: dotFalling 1s infinite linear;
  animation-delay: .1s;
}

.dot-falling::before, .dot-falling::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-falling::before {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: lightgrey;
  color: lightgrey;
  animation: dotFallingBefore 1s infinite linear;
  animation-delay: 0s;
}

.dot-falling::after {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: lightgrey;
  color: lightgrey;
  animation: dotFallingAfter 1s infinite linear;
  animation-delay: .2s;
}

@keyframes dotFalling {
  0% {
    box-shadow: 9999px -15px 0 0 rgba(152, 128, 255, 0);
  }
  25%,
  50%,
  75% {
    box-shadow: 9999px 0 0 0 lightgrey;
  }
  100% {
    box-shadow: 9999px 15px 0 0 rgba(152, 128, 255, 0);
  }
}

@keyframes dotFallingBefore {
  0% {
    box-shadow: 9974px -15px 0 0 rgba(152, 128, 255, 0);
  }
  25%,
  50%,
  75% {
    box-shadow: 9974px 0 0 0 lightgrey;
  }
  100% {
    box-shadow: 9974px 15px 0 0 rgba(152, 128, 255, 0);
  }
}

@keyframes dotFallingAfter {
  0% {
    box-shadow: 10014px -15px 0 0 rgba(152, 128, 255, 0);
  }
  25%,
  50%,
  75% {
    box-shadow: 10014px 0 0 0 lightgrey;
  }
  100% {
    box-shadow: 10014px 15px 0 0 rgba(152, 128, 255, 0);
  }
}

.animated-background {
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background-color: #312244;
  /* background: linear-gradient(to right, #eeeeee 8%, #bbbbbb 18%, #eeeeee 33%); */
  background-size: 800px 300px;
  height: 300px;
  position: relative;
}

@keyframes placeHolderShimmer {
  0% {
    background-color: #312244
  }
  50% {
    background-color: #bbbbbb
  }
  100% {
    background-color: #312244
  }
}

.static-background {
  background-color: #312244;
  background-size: 800px 200px;
  height: 70px;
  position: relative;
  margin-bottom: 20px;
}

.fade-in {
  animation: fadeIn ease 3s;
  -webkit-animation: fadeIn ease 3s;
  -moz-animation: fadeIn ease 3s;
  -o-animation: fadeIn ease 3s;
  -ms-animation: fadeIn ease 3s;
}

@keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}